import Package from "../package.json";
/*
  you probably do not want to commit changes to this file,
  you can set all the values in .env file to have this persisted and is ignored in source control
  NOTE: changes to .env file will however require you to restart webpack dev server
*/

export const isProduction = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV !== "development";

export const isDevelopment = () => process.env.NODE_ENV === "development";

export const host =
//  process.env.BACKEND_URL ?? "https://s3-integration.com/api/";
// "https://phoenix-backend-staging.com/api/";
 "https://dev7.phoenix-backend-staging.com/api/";
// "https://s3-integration.com/api/";
// "https://phoenix-backend-production.com/api/";

export const PHP_Domain = process.env.DOCUSIGN_URL ?? "https://c-papyrus.com";
// export const PHP_Domain = "https://c-papyrus.com";
// export const PHP_Domain = "https://s3-docusign.com";

export const SignalrHost =
  process.env.SIGNALR_URL ?? "https://s3-phoenix-hub.com/staging";
// export const SignalrHost = "https://s3-phoenix-hub.com";
// export const SignalrHost = "https://s3-phoenix-hub.com/staging";
// export const SignalrHost = "http://localhost:5000";

export const AppVersion = process.env.APP_VERSION ?? Package.version;
export const AppEnvironment = process.env.APP_ENVIRONMENT;

export const AZURE_CLIENT_ID = process.env.AZURE_CLIENT_ID;
// export const AZURE_CLIENT_ID = ""
// export const AZURE_CLIENT_ID = "91cbcc47-ef8e-4ab6-8171-c550e3b3c096"
